/*
Flaticon icon font: Flaticon
*/
@font-face {
	font-family: "Flaticon";
	src: url("../../assets/fonts/Flaticon.eot");
	src: url("../../assets/fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
	url("../../assets/fonts/Flaticon.woff2") format("woff2"),
	url("../../assets/fonts/Flaticon.woff") format("woff"),
	url("../../assets/fonts/Flaticon.ttf") format("truetype"),
	url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
	font-weight: normal;
	font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
		font-family: "Flaticon";
		src: url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
	}
}
[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
	font-family: Flaticon;
	font-style: normal;
}

.flaticon-leader:before { content: "\f100"; }
.flaticon-checkmark:before { content: "\f101"; }
.flaticon-law:before { content: "\f102"; }
.flaticon-family:before { content: "\f103"; }
.flaticon-inheritance:before { content: "\f104"; }
.flaticon-mortarboard:before { content: "\f105"; }
.flaticon-auction:before { content: "\f106"; }
.flaticon-vulnerability:before { content: "\f107"; }
.flaticon-experience:before { content: "\f108"; }
.flaticon-lawyer:before { content: "\f109"; }
.flaticon-balance:before { content: "\f10a"; }
.flaticon-pin:before { content: "\f10b"; }
.flaticon-email:before { content: "\f10c"; }
.flaticon-call:before { content: "\f10d"; }
.flaticon-judge:before { content: "\f10e"; }
.flaticon-medal:before { content: "\f10f"; }
.flaticon-team:before { content: "\f110"; }
.flaticon-download:before { content: "\f111"; }
.flaticon-money-bag:before { content: "\f112"; }
.flaticon-time:before { content: "\f113"; }
.flaticon-conversation:before { content: "\f114"; }
.flaticon-support:before { content: "\f115"; }